export default class SlitScan {
  constructor(canvasEl, imgSrc) {
    if (!canvasEl) {
      throw "Missing <canvas> for SlitScan class"
    }
    if (!imgSrc) {
      throw "Missing art for SlitScan class"
    }
    this.canvasEl = canvasEl
    this.ctx = this.canvasEl.getContext('2d')
    this.img = new Image()
    this.img.onload = this.start.bind(this)
    this.img.src = imgSrc

    // Prevent swipe scroll on mobile
    this.canvasEl.addEventListener('touchstart', (e) => { e.preventDefault() })
  }

  start() {
    requestAnimationFrame(this.render.bind(this))
  }

  mix(a, b, l) {
    return a + (b - a) * l
  }

  upDown(v) {
    return Math.sin(v) * 0.5 + 0.5
  }

  resize() {
    const { width, height, clientWidth, clientHeight } = this.canvasEl
    if (clientWidth !== width || clientHeight !== height) {
      this.canvasEl.width = clientWidth
      this.canvasEl.height = clientHeight
    }
  }

  render(time) {
    time *= 0.0004
    this.resize()

    const t1 = time
    const t2 = time * 0.37

    for (let dstY = 0; dstY < this.canvasEl.height; dstY++) {
      const v = dstY / this.canvasEl.height

      const off1 = Math.sin((v + 0.5) * this.mix(3, 12, this.upDown(t1))) * 300
      const off2 = Math.sin((v + 0.5) * this.mix(3, 12, this.upDown(t2))) * 300
      const off = off1 + off2

      let srcY = dstY * this.img.height / this.canvasEl.height + off
      srcY = Math.max(0, Math.min(this.img.height - 1, srcY))

      this.ctx.drawImage(
        this.img,
        0, srcY, this.img.width, 1,
        0, dstY, this.canvasEl.width, 1)
    }

    requestAnimationFrame(this.render.bind(this))
  }
}