import React, {useContext, useState} from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import {AppContext} from '../../contexts/app-context'

import styles from './contact.module.css'
import {cn} from '../../lib/helpers'

const Contact = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const {buttonColor, showGlow} = useContext(AppContext)
  const inputClassName = showGlow ? cn(styles.input, 'focus-aura') : styles.input

  const handleInputChange = ({ target: { value } }) => {
    setEmail(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const result = await addToMailchimp(email);
    setMessage(result.msg)
  }

  return (
    <form className={styles.form}>
      <label>
        <span className={styles.screenReaderText}>Email address</span>
        <input className={inputClassName}
          type='email'
          name='email'
          placeholder='Email address'
          value={email}
          onChange={handleInputChange}
        />
      </label>
      <button className={styles.button} onClick={handleSubmit} style={{ backgroundColor: buttonColor }}>SUBSCRIBE</button>
      { message && <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }} /> }
    </form>
  )
}

export default Contact
