import {graphql, navigate, StaticQuery} from 'gatsby'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useLocation} from '@reach/router'

import Container from '../components/container'
import Footer from '../components/footer'
import GraphQLErrorList from '../components/graphql-error-list'
import Header from '../components/header'
import {TabToPage} from '../components/nav'
import SEO from '../components/seo'

import {AppContext} from '../contexts/app-context'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import SlitScan from '../lib/slit-scan'

import '../styles/layout.css'

const query = graphql`
  query LayoutQuery {
    settings: sanitySeoAndSiteSettings(_id: {regex: "/(drafts.|)seoAndSiteSettings/"}) {
      title
      description
      keywords
      textColor {
        hex
      }
      buttonColor {
        hex
      }
      enableGlow
      enableRipple
      enableSlitScan
      cover {
        asset {
          _id
        }
      }
    }
    nav: sanityNav {
      titleImage {
        asset {
          _id
        }
      }
      tabOrder {
        displayText
        url
        isHidden
      }
    }
    footer: sanityFooter(_id: {regex: "/(drafts.|)footer/"}) {
      footerIcons {
        name
        iconImage {
          asset {
            _id
          }
        }
        url
      }
      copyrightText
    }
  }
`

export const errorMessage = (schema) => { throw new Error(`Missing ${schema}. Open the studio at http://localhost:3333`) }

function Layout ({ children }) {
  const { setButtonColor, setShowGlow, setShowRipple } = useContext(AppContext)

  return (
    <StaticQuery
      query={query}
      render={({ settings, nav, footer }, errors) => {
        if (errors) return <GraphQLErrorList errors={errors} />
        if (!settings) errorMessage(`"Site Settings"`)
        if (!nav) errorMessage(`"Nav"`)
        if (!footer) errorMessage(`"Footer"`)

        // Set active tab to page title
        const location = useLocation()
        const pathname = location.pathname.replace('/', '')
        const currentTab = !!pathname.length ? (pathname.charAt(0).toUpperCase() + pathname.slice(1)) : ''
        const title = `${currentTab ? currentTab + ' – ' : ''}${settings.title}`

        const [showPage, setShowPage] = useState(false)

        // Canvas background
        let canvasClassName =
          !settings.cover.asset
            ? 'canvas canvas-gradient'
            : !settings.enableSlitScan
              ? 'canvas canvas-bg'
              : 'canvas no-pointer'

        const canvasEl = useRef(null)
        const src = !!settings.cover.asset ? imageUrlFor(buildImageObj(settings.cover)).url() : ''
        const titleImageSrc = !!settings.cover.asset ? imageUrlFor(buildImageObj(nav.titleImage)).url() : ''

        useEffect(() => {
          // Set button color to app context
          setButtonColor(settings.buttonColor.hex)
          setShowGlow(settings.enableGlow)
          setShowRipple(settings.enableRipple)

          if (!!src && !!canvasEl && !!canvasEl.current) {
            new SlitScan(canvasEl.current, src)
          }

          // Redirect logic for page visibility settings.
          // If no early return after a redirect, show the page.
          const pathname = (typeof window !== undefined) && window.location && window.location.pathname
          setShowPage(nav.tabOrder.reduce((acc, {displayText, isHidden}) => {
            const page = TabToPage[displayText]
            if (page === pathname && isHidden) {
              navigate('/')
              return false
            }
            return acc
          }, true))
        }, [src, canvasEl, settings, nav])

        return (
          <>
            {
              settings.enableSlitScan
                ? <canvas ref={canvasEl} className={canvasClassName} />
                : <div className={canvasClassName} style={{ backgroundImage: `url(${src})` }} />
            }
            {
              showPage &&
                <div className={'layout'} style={{ color: `${settings.textColor.hex}` }}>
                  <SEO title={title} description={settings.description} keywords={settings.keywords} />
                  <section>
                    <Header
                      title={settings.title}
                      tabs={nav}
                      currentTab={currentTab}
                      titleImage={titleImageSrc}
                    />
                    <Container>
                      {children}
                    </Container>
                  </section>
                  <Footer footerLinks={footer.footerIcons} copyrightText={footer.copyrightText} />
                </div>
            }
          </>
        )
      }}
    />
  )
}

export default Layout
