import React from 'react'

import Nav from '../nav'
import Title from '../title'

import styles from './header.module.css'

const Header = ({
  currentTab,
  tabs,
  title = `L'Rain`,
  titleImage,
}) => (
  <header>
    <h1 hidden>{title}</h1>
    <div className={styles.wrapper}>
      <Title titleImage={titleImage} />
    </div>
    <Nav currentTab={currentTab} tabs={tabs} />
  </header>
)

export default Header
