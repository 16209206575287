import {Link} from 'gatsby'
import React from 'react'

import styles from './nav.module.css'
import {paragraph} from '../typography.module.css'
import {cn} from '../../lib/helpers'

export const TabToPage = Object.freeze({
  About: '/about',
  Merch: null,
  Music: null,
  Press: '/press',
  Projects: '/projects',
  Tours: '/tours',
  Videos: '/videos',
})

const Nav = ({ currentTab = '', tabs = {} }) => (
  <nav className={styles.nav}>
    <ul>
      {
        tabs.tabOrder.map(({ displayText, url, isHidden }, i) => {
          const tabClassName = displayText === currentTab
            ? cn(paragraph, styles.tab, styles.highlighted)
            : cn(paragraph, styles.tab)

          return isHidden
            ? <></>
            : !!url
              ? <li key={i}>
                  <a className={tabClassName} href={url} target='_blank'>
                    {displayText}
                  </a>
                </li>
              : <li key={i}>
                  <Link className={tabClassName} to={TabToPage[displayText] || '/'}>
                    {displayText}
                  </Link>
                </li>
        })
      }
    </ul>
  </nav>
)

export default Nav
