import React from 'react'
import Icon from '../../icon'

import styles from './footer-links.module.css'

import { buildImageObj } from '../../../lib/helpers'
import { imageUrlFor } from '../../../lib/image-url'

const FooterLinks = ({ footerLinks = [] }) => (
  <div className={styles.wrapper}>
    <ul className={styles.ul}>
      {
        footerLinks.map(({ name, iconImage, url }) => {
          let src = imageUrlFor(buildImageObj(iconImage)).url()
          return (
            <li key={String(src)}>
              <a href={url} target='_blank'>
                <Icon symbol={name} src={src} />
              </a>
            </li>
          )
        })
      }
    </ul>
  </div>
)

export default FooterLinks