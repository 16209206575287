import {Link} from 'gatsby'
import React, {useContext, useEffect, useRef} from 'react'
import styles from './title.module.css'
import {AppContext} from '../../contexts/app-context'

const Title = ({ titleImage = '' }) => {
  const titleEl = useRef(null)
  const {showRipple} = useContext(AppContext)

  useEffect(() => {
    if (showRipple) {
      const $title = $(titleEl.current)
      $title.ripples({
        dropRadius: 10,
        perturbance: 0.02,
        resolution: 1028,
      })

      const bounds = titleEl.current.getBoundingClientRect()
      const x1 = bounds.width / 6
      const x2 = bounds.width / 2
      const x3 = bounds.width - (bounds.width / 6)
      const y = bounds.height / 2

      setInterval(() => $title.ripples('drop', x1, y, 5, 0.02), 6000)
      setInterval(() => $title.ripples('drop', x2, y, 5, 0.02), 2000)
      setInterval(() => $title.ripples('drop', x3, y, 5, 0.02), 4000)
    }
  }, [titleEl, showRipple])

  return (
    <Link to={'/'} ref={titleEl} className={styles.title} style={{ backgroundImage: `url(${titleImage})` }} />
  )
}

export default Title
