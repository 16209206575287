import React from 'react'
import Contact from '../contact'
import FooterLinks from './footer-links'

import styles from './footer.module.css'
import {paragraph} from '../typography.module.css'
import {cn} from '../../lib/helpers'

const Footer = ({ footerLinks, copyrightText = 'Throw Shade Publishing LLC' }) => (
  <footer className={styles.footer}>
    <div className={styles.footerWrapper}>
      <FooterLinks footerLinks={footerLinks} />
      <Contact />
      <div className={cn(paragraph, styles.siteInfo)}>
        {copyrightText}
      </div>
    </div>
  </footer>
)

export default Footer
